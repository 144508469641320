import React, { useEffect, useState } from "react";
import Posts from "../components/Posts";
import Spinner from "../components/Spinner";

function Home() {
    const [appState, setAppState] = useState({
        loading: false,
        posts: null,
    });

    function delay(ms) {
        return new Promise((resolve) => setTimeout(resolve, ms));
    }
    useEffect(() => {
        async function fetchData() {
            setAppState({ loading: true });
            await delay(1500);

            const response = await fetch(
                "https://jsonplaceholder.typicode.com/posts"
            );
            const data = await response.json();
            const posts = data.slice(0, 5);

            setAppState({ loading: false, posts: posts });
        }
        fetchData();
    }, [setAppState]);

    return appState.loading ? (
        <Spinner></Spinner>
    ) : (
        <Posts isLoading={appState.loading} posts={appState.posts} />
    );
}
export default Home;
